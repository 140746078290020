<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ this.$route.name }}</div>
        <a-button type="primary" @click="handleEdit({ id: 0 })">新建</a-button>
      </div> 
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
					<span slot="num" slot-scope="text">
						<span v-if="text==0">不限</span>
						<span v-if="text>0">{{text}}</span>
					</span>
          <span slot="action" slot-scope="text, record">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确认删除？" @confirm="del(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <create-form ref="createModal" @ok="handleOk" />
  </div>
</template>

<script>
import CreateForm from "./modules/CreateForm"
import { coupon, coupon_add, coupon_del } from "../../../api/index.js"

export default {
  components: {
    CreateForm,
  },
  methods: {
    async more(e) {
			this.queryParam.page = e.current
      let res = await coupon(this.queryParam);
      if (res.code == 200) {
        this.item = res.data.data;
        this.page.total = res.data.total;
        this.page.current = res.data.current_page;
      }
    },
    handleEdit(record) {
      this.id = record.id;
      this.$refs.createModal.init(record)
    },
    handleOk(e) {
			coupon_add({id:this.id,item:e}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg)
					this.$refs.createModal.cancel()
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg)
				}
			})   
    },
    del(e) {
			coupon_del({id:e.id}).then((res)=>{
				if(res.code==200){
					this.$message.success(res.msg)
					this.more({ current: this.page.current })
				}else{
					this.$message.error(res.msg)
				}
			})
		}
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { title:'编号', dataIndex:'id', width:80 },
        { title:'优惠券名称', dataIndex:'title'},
        { title:'抵扣金额', dataIndex:'price', width:100},
        { title:'满足金额', dataIndex:'amount', width:100, scopedSlots:{ customRender: 'num' }},
        { title:'每人领取', dataIndex:'get_num', width:100, scopedSlots:{ customRender: 'num' }},
        { title:'到期时间', dataIndex:'end_time', width:120},
        { title:"排序", dataIndex:"sort", width:80 },
        { title:"更新时间", dataIndex: "update_time", width: 180 },
        { title:"操作", width:120, scopedSlots:{customRender: "action" }}
      ],
      item: []
    };
  }
};
</script>