<template>
  <a-modal :title="this.$route.name" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" :label-col="{span:6}" :wrapper-col="{span:16}">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
      <a-form-item label="优惠券名称">
        <a-input v-decorator="['title',{rules:[{required:true,message:''}]}]" />
      </a-form-item>
			<a-form-item label="抵扣金额">
			  <a-input-number v-decorator="['price',{initialValue:0,rules:[{required:true,message:''}]}]" />
			</a-form-item>
			<a-form-item label="满足金额">
			  <a-input-number v-decorator="['amount',{initialValue:0,rules:[{required:true,message:''}]}]" />
			</a-form-item>
			<a-form-item label="每人领取数量">
			  <a-input-number v-decorator="['get_num',{initialValue:0,rules:[{required:true,message:''}]}]" />
			</a-form-item>
			<a-form-item label="到期日期">
			  <a-date-picker v-decorator="['end_time',{rules:[{required:true,message:''}]}]" />
			</a-form-item>
			<a-form-item label="分类排序">
			  <a-input-number v-decorator="['sort',{initialValue:0,rules:[{required:true,message:''}]}]" />
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"

// 表单字段
const fields = ['title','price','amount','get_num','end_time','sort']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this)
    }
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v));
  },
  methods: {
    init(e) {
      this.form.setFieldsValue(pick(e, fields))
      this.visible = true
    },
    cancel() {
			this.form.resetFields()
      this.visible = false
    },
    ok() {
      this.form.validateFields((errors, values) => {
        if (!errors){
					this.$emit("ok", values)
					this.form.resetFields()
				} 
      })
    }
  },
}
</script>
